<template>
  <div class="mt-8">

    <b-table
      :fields="fields"
      :items="items"
    >
      <template #cell(icon)="data">
        <i :class="data.item.icon" style="font-size: 42px; color: rgb(50, 50, 50);"></i>
      </template>
    </b-table>
  </div>
</template>

<script>


export default {
  components: {

  },

  computed: {
    items() {
      const rows = [];

      for (const fa of this.fa_icons) {
        rows.push({ fa_name: fa, icon: 'fa ' + fa });
      }

      return rows;
    }
  },

  data() {
    return {
      fields: [
        { key: 'fa_name', label: 'fa name' },
        { key: 'icon', label: 'icon' }
      ],
      
    fa_icons: [
      'fa-500px',
      'fa-accessible-icon',
      'fa-accusoft',
      'fa-acquisitions-incorporated',
      'fa-ad',
      'fa-address-book',
      'fa-address-card',
      'fa-adjust',
      'fa-adn',
      'fa-adversal',
      'fa-affiliatetheme',
      'fa-air-freshener',
      'fa-airbnb',
      'fa-algolia',
      'fa-align-center',
      'fa-align-justify',
      'fa-align-left',
      'fa-align-center v-icon',
      'fa-alipay',
      'fa-allergies',
      'fa-amazon',
      'fa-amazon-pay',
      'fa-ambulance',
      'fa-american-sign-language-interpreting',
      'fa-amilia',
      'fa-anchor',
      'fa-android',
      'fa-angellist',
      'fa-angle-double-down',
      'fa-angle-double-left',
      'fa-angle-double-right',
      'fa-angle-double-up',
      'fa-angle-down',
      'fa-angle-left',
      'fa-angle-right',
      'fa-angle-up',
      'fa-angry',
      'fa-angrycreative',
      'fa-angular',
      'fa-ankh',
      'fa-app-store',
      'fa-app-store-ios',
      'fa-apper',
      'fa-apple',
      'fa-apple-alt',
      'fa-apple-pay',
      'fa-archive',
      'fa-archway',
      'fa-arrow-alt-circle-down',
      'fa-arrow-alt-circle-left',
      'fa-arrow-alt-circle-right',
      'fa-arrow-alt-circle-up',
      'fa-arrow-circle-down',
      'fa-arrow-circle-left',
      'fa-arrow-circle-right',
      'fa-arrow-circle-up',
      'fa-arrow-down',
      'fa-arrow-left',
      'fa-arrow-right',
      'fa-arrow-up',
      'fa-arrows-alt',
      'fa-arrows-alt-h',
      'fa-arrows-alt-v',
      'fa-artstation',
      'fa-assistive-listening-systems',
      'fa-asterisk',
      'fa-asymmetrik',
      'fa-at',
      'fa-atlas',
      'fa-atlassian',
      'fa-atom',
      'fa-audible',
      'fa-audio-description',
      'fa-autoprefixer',
      'fa-avianex',
      'fa-aviato',
      'fa-award',
      'fa-aws',
      'fa-baby',
      'fa-baby-carriage',
      'fa-backspace',
      'fa-backward',
      'fa-bacon',
      'fa-bacteria',
      'fa-bacterium',
      'fa-bahai',
      'fa-balance-scale',
      'fa-balance-scale-left',
      'fa-balance-scale-right',
      'fa-ban',
      'fa-band-aid',
      'fa-bandcamp',
      'fa-barcode',
      'fa-bars',
      'fa-baseball-ball',
      'fa-basketball-ball',
      'fa-bath',
      'fa-battery-empty',
      'fa-battery-full',
      'fa-battery-half',
      'fa-battery-quarter',
      'fa-battery-three-quarters',
      'fa-battle-net',
      'fa-bed',
      'fa-beer',
      'fa-behance',
      'fa-behance-square',
      'fa-bell',
      'fa-bell-slash',
      'fa-bezier-curve',
      'fa-bible',
      'fa-bicycle',
      'fa-biking',
      'fa-bimobject',
      'fa-binoculars',
      'fa-biohazard',
      'fa-birthday-cake',
      'fa-bitbucket',
      'fa-bitcoin',
      'fa-bity',
      'fa-black-tie',
      'fa-blackberry',
      'fa-blender',
      'fa-blender-phone',
      'fa-blind',
      'fa-blog',
      'fa-blogger',
      'fa-blogger-b',
      'fa-bluetooth',
      'fa-bluetooth-b',
      'fa-bold',
      'fa-bolt',
      'fa-bomb',
      'fa-bone',
      'fa-bong',
      'fa-book',
      'fa-book-dead',
      'fa-book-medical',
      'fa-book-open',
      'fa-book-reader',
      'fa-bookmark',
      'fa-bootstrap',
      'fa-border-all',
      'fa-border-none',
      'fa-border-style',
      'fa-bowling-ball',
      'fa-box',
      'fa-box-open',
      'fa-box-tissue',
      'fa-boxes',
      'fa-braille',
      'fa-brain',
      'fa-bread-slice',
      'fa-briefcase',
      'fa-briefcase-medical',
      'fa-broadcast-tower',
      'fa-broom',
      'fa-brush',
      'fa-btc',
      'fa-buffer',
      'fa-bug',
      'fa-building',
      'fa-bullhorn',
      'fa-bullseye',
      'fa-burn',
      'fa-buromobelexperte',
      'fa-bus',
      'fa-bus-alt',
      'fa-business-time',
      'fa-buy-n-large',
      'fa-buysellads',
      'fa-calculator',
      'fa-calendar',
      'fa-calendar-alt',
      'fa-calendar-check',
      'fa-calendar-day',
      'fa-calendar-minus',
      'fa-calendar-plus',
      'fa-calendar-times',
      'fa-calendar-week',
      'fa-camera',
      'fa-camera-retro',
      'fa-campground',
      'fa-canadian-maple-leaf',
      'fa-candy-cane',
      'fa-cannabis',
      'fa-capsules',
      'fa-car',
      'fa-car-alt',
      'fa-car-battery',
      'fa-car-crash',
      'fa-car-side',
      'fa-caravan',
      'fa-caret-down',
      'fa-caret-left',
      'fa-caret-right',
      'fa-caret-square-down',
      'fa-caret-square-left',
      'fa-caret-square-right',
      'fa-caret-square-up',
      'fa-caret-up',
      'fa-carrot',
      'fa-cart-arrow-down',
      'fa-cart-plus',
      'fa-cash-register',
      'fa-cat',
      'fa-cc-amazon-pay',
      'fa-cc-amex',
      'fa-cc-apple-pay',
      'fa-cc-diners-club',
      'fa-cc-discover',
      'fa-cc-jcb',
      'fa-cc-mastercard',
      'fa-cc-paypal',
      'fa-cc-stripe',
      'fa-cc-visa',
      'fa-centercode',
      'fa-centos',
      'fa-certificate',
      'fa-chair',
      'fa-chalkboard',
      'fa-chalkboard-teacher',
      'fa-charging-station',
      'fa-chart-area',
      'fa-chart-bar',
      'fa-chart-line',
      'fa-chart-pie',
      'fa-check',
      'fa-check-circle',
      'fa-check-double',
      'fa-check-square',
      'fa-cheese',
      'fa-chess',
      'fa-chess-bishop',
      'fa-chess-board',
      'fa-chess-king',
      'fa-chess-knight',
      'fa-chess-pawn',
      'fa-chess-queen',
      'fa-chess-rook',
      'fa-chevron-circle-down',
      'fa-chevron-circle-left',
      'fa-chevron-circle-right',
      'fa-chevron-circle-up',
      'fa-chevron-down',
      'fa-chevron-left',
      'fa-chevron-right',
      'fa-chevron-up',
      'fa-child',
      'fa-chrome',
      'fa-chromecast',
      'fa-church',
      'fa-circle',
      'fa-circle-notch',
      'fa-city',
      'fa-clinic-medical',
      'fa-clipboard',
      'fa-clipboard-check',
      'fa-clipboard-list',
      'fa-clock',
      'fa-clone',
      'fa-closed-captioning',
      'fa-cloud',
      'fa-cloud-download-alt',
      'fa-cloud-meatball',
      'fa-cloud-moon',
      'fa-cloud-moon-rain',
      'fa-cloud-rain',
      'fa-cloud-showers-heavy',
      'fa-cloud-sun',
      'fa-cloud-sun-rain',
      'fa-cloud-upload-alt',
      'fa-cloudflare',
      'fa-cloudscale',
      'fa-cloudsmith',
      'fa-cloudversify',
      'fa-cocktail',
      'fa-code',
      'fa-code-branch',
      'fa-codepen',
      'fa-codiepie',
      'fa-coffee',
      'fa-cog',
      'fa-cogs',
      'fa-coins',
      'fa-columns',
      'fa-comment',
      'fa-comment-alt',
      'fa-comment-dollar',
      'fa-comment-dots',
      'fa-comment-medical',
      'fa-comment-slash',
      'fa-comments',
      'fa-comments-dollar',
      'fa-compact-disc',
      'fa-compass',
      'fa-compress',
      'fa-compress-alt',
      'fa-compress-arrows-alt',
      'fa-concierge-bell',
      'fa-confluence',
      'fa-connectdevelop',
      'fa-contao',
      'fa-cookie',
      'fa-cookie-bite',
      'fa-copy',
      'fa-copyright',
      'fa-cotton-bureau',
      'fa-couch',
      'fa-cpanel',
      'fa-creative-commons',
      'fa-creative-commons-by',
      'fa-creative-commons-nc',
      'fa-creative-commons-nc-eu',
      'fa-creative-commons-nc-jp',
      'fa-creative-commons-nd',
      'fa-creative-commons-pd',
      'fa-creative-commons-pd-alt',
      'fa-creative-commons-remix',
      'fa-creative-commons-sa',
      'fa-creative-commons-sampling',
      'fa-creative-commons-sampling-plus',
      'fa-creative-commons-share',
      'fa-creative-commons-zero',
      'fa-credit-card',
      'fa-critical-role',
      'fa-crop',
      'fa-crop-alt',
      'fa-cross',
      'fa-crosshairs',
      'fa-crow',
      'fa-crown',
      'fa-crutch',
      'fa-css3',
      'fa-css3-alt',
      'fa-cube',
      'fa-cubes',
      'fa-cut',
      'fa-cuttlefish',
      'fa-d-and-d',
      'fa-d-and-d-beyond',
      'fa-dailymotion',
      'fa-dashcube',
      'fa-database',
      'fa-deaf',
      'fa-deezer',
      'fa-delicious',
      'fa-democrat',
      'fa-deploydog',
      'fa-deskpro',
      'fa-desktop',
      'fa-dev',
      'fa-deviantart',
      'fa-dharmachakra',
      'fa-dhl',
      'fa-diagnoses',
      'fa-diaspora',
      'fa-dice',
      'fa-dice-d20',
      'fa-dice-d6',
      'fa-dice-five',
      'fa-dice-four',
      'fa-dice-one',
      'fa-dice-six',
      'fa-dice-three',
      'fa-dice-two',
      'fa-digg',
      'fa-digital-ocean',
      'fa-digital-tachograph',
      'fa-directions',
      'fa-discord',
      'fa-discourse',
      'fa-disease',
      'fa-divide',
      'fa-dizzy',
      'fa-dna',
      'fa-dochub',
      'fa-docker',
      'fa-dog',
      'fa-dollar-sign',
      'fa-dolly',
      'fa-dolly-flatbed',
      'fa-donate',
      'fa-door-closed',
      'fa-door-open',
      'fa-dot-circle',
      'fa-dove',
      'fa-download',
      'fa-draft2digital',
      'fa-drafting-compass',
      'fa-dragon',
      'fa-draw-polygon',
      'fa-dribbble',
      'fa-dribbble-square',
      'fa-dropbox',
      'fa-drum',
      'fa-drum-steelpan',
      'fa-drumstick-bite',
      'fa-drupal',
      'fa-dumbbell',
      'fa-dumpster',
      'fa-dumpster-fire',
      'fa-dungeon',
      'fa-dyalog',
      'fa-earlybirds',
      'fa-ebay',
      'fa-edge',
      'fa-edge-legacy',
      'fa-edit',
      'fa-egg',
      'fa-eject',
      'fa-elementor',
      'fa-ellipsis-h',
      'fa-ellipsis-v',
      'fa-ello',
      'fa-ember',
      'fa-empire',
      'fa-envelope',
      'fa-envelope-open',
      'fa-envelope-open-text',
      'fa-envelope-square',
      'fa-envira',
      'fa-equals',
      'fa-eraser',
      'fa-erlang',
      'fa-ethereum',
      'fa-ethernet',
      'fa-etsy',
      'fa-euro-sign',
      'fa-evernote',
      'fa-exchange-alt',
      'fa-exclamation',
      'fa-exclamation-circle',
      'fa-exclamation-triangle',
      'fa-expand',
      'fa-expand-alt',
      'fa-expand-arrows-alt',
      'fa-expeditedssl',
      'fa-external-link-alt',
      'fa-external-link-square-alt',
      'fa-eye',
      'fa-eye-dropper',
      'fa-eye-slash',
      'fa-facebook',
      'fa-facebook-f',
      'fa-facebook-messenger',
      'fa-facebook-square',
      'fa-fan',
      'fa-fantasy-flight-games',
      'fa-fast-backward',
      'fa-fast-forward',
      'fa-faucet',
      'fa-fax',
      'fa-feather',
      'fa-feather-alt',
      'fa-fedex',
      'fa-fedora',
      'fa-female',
      'fa-fighter-jet',
      'fa-figma',
      'fa-file',
      'fa-file-alt',
      'fa-file-archive',
      'fa-file-audio',
      'fa-file-code',
      'fa-file-contract',
      'fa-file-csv',
      'fa-file-download',
      'fa-file-excel',
      'fa-file-export',
      'fa-file-image',
      'fa-file-import',
      'fa-file-invoice',
      'fa-file-invoice-dollar',
      'fa-file-medical',
      'fa-file-medical-alt',
      'fa-file-pdf',
      'fa-file-powerpoint',
      'fa-file-prescription',
      'fa-file-signature',
      'fa-file-upload',
      'fa-file-video',
      'fa-file-word',
      'fa-fill',
      'fa-fill-drip',
      'fa-film',
      'fa-filter',
      'fa-fingerprint',
      'fa-fire',
      'fa-fire-alt',
      'fa-fire-extinguisher',
      'fa-firefox',
      'fa-firefox-browser',
      'fa-first-aid',
      'fa-first-order',
      'fa-first-order-alt',
      'fa-firstdraft',
      'fa-fish',
      'fa-fist-raised',
      'fa-flag',
      'fa-flag-checkered',
      'fa-flag-usa',
      'fa-flask',
      'fa-flickr',
      'fa-flipboard',
      'fa-flushed',
      'fa-fly',
      'fa-folder',
      'fa-folder-minus',
      'fa-folder-open',
      'fa-folder-plus',
      'fa-font',
      'fa-font-awesome',
      'fa-font-awesome-alt',
      'fa-font-awesome-flag',
      'fa-font-awesome-logo-full',
      'fa-fonticons',
      'fa-fonticons-fi',
      'fa-football-ball',
      'fa-fort-awesome',
      'fa-fort-awesome-alt',
      'fa-forumbee',
      'fa-forward',
      'fa-foursquare',
      'fa-free-code-camp',
      'fa-freebsd',
      'fa-frog',
      'fa-frown',
      'fa-frown-open',
      'fa-fulcrum',
      'fa-funnel-dollar',
      'fa-futbol',
      'fa-galactic-republic',
      'fa-galactic-senate',
      'fa-gamepad',
      'fa-gas-pump',
      'fa-gavel',
      'fa-gem',
      'fa-genderless',
      'fa-get-pocket',
      'fa-gg',
      'fa-gg-circle',
      'fa-ghost',
      'fa-gift',
      'fa-gifts',
      'fa-git',
      'fa-git-alt',
      'fa-git-square',
      'fa-github',
      'fa-github-alt',
      'fa-github-square',
      'fa-gitkraken',
      'fa-gitlab',
      'fa-gitter',
      'fa-glass-cheers',
      'fa-glass-martini',
      'fa-glass-martini-alt',
      'fa-glass-whiskey',
      'fa-glasses',
      'fa-glide',
      'fa-glide-g',
      'fa-globe',
      'fa-globe-africa',
      'fa-globe-americas',
      'fa-globe-asia',
      'fa-globe-europe',
      'fa-gofore',
      'fa-golf-ball',
      'fa-goodreads',
      'fa-goodreads-g',
      'fa-google',
      'fa-google-drive',
      'fa-google-pay',
      'fa-google-play',
      'fa-google-plus',
      'fa-google-plus-g',
      'fa-google-plus-square',
      'fa-google-wallet',
      'fa-gopuram',
      'fa-graduation-cap',
      'fa-gratipay',
      'fa-grav',
      'fa-greater-than',
      'fa-greater-than-equal',
      'fa-grimace',
      'fa-grin',
      'fa-grin-alt',
      'fa-grin-beam',
      'fa-grin-beam-sweat',
      'fa-grin-hearts',
      'fa-grin-squint',
      'fa-grin-squint-tears',
      'fa-grin-stars',
      'fa-grin-tears',
      'fa-grin-tongue',
      'fa-grin-tongue-squint',
      'fa-grin-tongue-wink',
      'fa-grin-wink',
      'fa-grip-horizontal',
      'fa-grip-lines',
      'fa-grip-lines-vertical',
      'fa-grip-vertical',
      'fa-gripfire',
      'fa-grunt',
      'fa-guilded',
      'fa-guitar',
      'fa-gulp',
      'fa-h-square',
      'fa-hacker-news',
      'fa-hacker-news-square',
      'fa-hackerrank',
      'fa-hamburger',
      'fa-hammer',
      'fa-hamsa',
      'fa-hand-holding',
      'fa-hand-holding-heart',
      'fa-hand-holding-medical',
      'fa-hand-holding-usd',
      'fa-hand-holding-water',
      'fa-hand-lizard',
      'fa-hand-middle-finger',
      'fa-hand-paper',
      'fa-hand-peace',
      'fa-hand-point-down',
      'fa-hand-point-left',
      'fa-hand-point-right',
      'fa-hand-point-up',
      'fa-hand-pointer',
      'fa-hand-rock',
      'fa-hand-scissors',
      'fa-hand-sparkles',
      'fa-hand-spock',
      'fa-hands',
      'fa-hands-helping',
      'fa-hands-wash',
      'fa-handshake',
      'fa-handshake-alt-slash',
      'fa-handshake-slash',
      'fa-hanukiah',
      'fa-hard-hat',
      'fa-hashtag',
      'fa-hat-cowboy',
      'fa-hat-cowboy-side',
      'fa-hat-wizard',
      'fa-hdd',
      'fa-head-side-cough',
      'fa-head-side-cough-slash',
      'fa-head-side-mask',
      'fa-head-side-virus',
      'fa-heading',
      'fa-headphones',
      'fa-headphones-alt',
      'fa-headset',
      'fa-heart',
      'fa-heart-broken',
      'fa-heartbeat',
      'fa-helicopter',
      'fa-highlighter',
      'fa-hiking',
      'fa-hippo',
      'fa-hips',
      'fa-hire-a-helper',
      'fa-history',
      'fa-hive',
      'fa-hockey-puck',
      'fa-holly-berry',
      'fa-home',
      'fa-hooli',
      'fa-hornbill',
      'fa-horse',
      'fa-horse-head',
      'fa-hospital',
      'fa-hospital-alt',
      'fa-hospital-symbol',
      'fa-hospital-user',
      'fa-hot-tub',
      'fa-hotdog',
      'fa-hotel',
      'fa-hotjar',
      'fa-hourglass',
      'fa-hourglass-end',
      'fa-hourglass-half',
      'fa-hourglass-start',
      'fa-house-damage',
      'fa-house-user',
      'fa-houzz',
      'fa-hryvnia',
      'fa-html5',
      'fa-hubspot',
      'fa-i-cursor',
      'fa-ice-cream',
      'fa-icicles',
      'fa-icons',
      'fa-id-badge',
      'fa-id-card',
      'fa-id-card-alt',
      'fa-ideal',
      'fa-igloo',
      'fa-image',
      'fa-images',
      'fa-imdb',
      'fa-inbox',
      'fa-indent',
      'fa-industry',
      'fa-infinity',
      'fa-info',
      'fa-info-circle',
      'fa-innosoft',
      'fa-instagram',
      'fa-instagram-square',
      'fa-instalod',
      'fa-intercom',
      'fa-internet-explorer',
      'fa-invision',
      'fa-ioxhost',
      'fa-italic',
      'fa-itch-io',
      'fa-itunes',
      'fa-itunes-note',
      'fa-java',
      'fa-jedi',
      'fa-jedi-order',
      'fa-jenkins',
      'fa-jira',
      'fa-joget',
      'fa-joint',
      'fa-joomla',
      'fa-journal-whills',
      'fa-js',
      'fa-js-square',
      'fa-jsfiddle',
      'fa-kaaba',
      'fa-kaggle',
      'fa-key',
      'fa-keybase',
      'fa-keyboard',
      'fa-keycdn',
      'fa-khanda',
      'fa-kickstarter',
      'fa-kickstarter-k',
      'fa-kiss',
      'fa-kiss-beam',
      'fa-kiss-wink-heart',
      'fa-kiwi-bird',
      'fa-korvue',
      'fa-landmark',
      'fa-language',
      'fa-laptop',
      'fa-laptop-code',
      'fa-laptop-house',
      'fa-laptop-medical',
      'fa-laravel',
      'fa-lastfm',
      'fa-lastfm-square',
      'fa-laugh',
      'fa-laugh-beam',
      'fa-laugh-squint',
      'fa-laugh-wink',
      'fa-layer-group',
      'fa-leaf',
      'fa-leanpub',
      'fa-lemon',
      'fa-less',
      'fa-less-than',
      'fa-less-than-equal',
      'fa-level-down-alt',
      'fa-level-up-alt',
      'fa-life-ring',
      'fa-lightbulb',
      'fa-line',
      'fa-link',
      'fa-linkedin',
      'fa-linkedin-in',
      'fa-linode',
      'fa-linux',
      'fa-lira-sign',
      'fa-list',
      'fa-list-alt',
      'fa-list-ol',
      'fa-list-ul',
      'fa-location-arrow',
      'fa-lock',
      'fa-lock-open',
      'fa-long-arrow-alt-down',
      'fa-long-arrow-alt-left',
      'fa-long-arrow-alt-right',
      'fa-long-arrow-alt-up',
      'fa-low-vision',
      'fa-luggage-cart',
      'fa-lungs',
      'fa-lungs-virus',
      'fa-lyft',
      'fa-magento',
      'fa-magic',
      'fa-magnet',
      'fa-mail-bulk',
      'fa-mailchimp',
      'fa-male',
      'fa-mandalorian',
      'fa-map',
      'fa-map-marked',
      'fa-map-marked-alt',
      'fa-map-marker',
      'fa-map-marker-alt',
      'fa-map-pin',
      'fa-map-signs',
      'fa-markdown',
      'fa-marker',
      'fa-mars',
      'fa-mars-double',
      'fa-mars-stroke',
      'fa-mars-stroke-h',
      'fa-mars-stroke-v',
      'fa-mask',
      'fa-mastodon',
      'fa-maxcdn',
      'fa-mdb',
      'fa-medal',
      'fa-medapps',
      'fa-medium',
      'fa-medium-m',
      'fa-medkit',
      'fa-medrt',
      'fa-meetup',
      'fa-megaport',
      'fa-meh',
      'fa-meh-blank',
      'fa-meh-rolling-eyes',
      'fa-memory',
      'fa-mendeley',
      'fa-menorah',
      'fa-mercury',
      'fa-meteor',
      'fa-microblog',
      'fa-microchip',
      'fa-microphone',
      'fa-microphone-alt',
      'fa-microphone-alt-slash',
      'fa-microphone-slash',
      'fa-microscope',
      'fa-microsoft',
      'fa-minus',
      'fa-minus-circle',
      'fa-minus-square',
      'fa-mitten',
      'fa-mix',
      'fa-mixcloud',
      'fa-mixer',
      'fa-mizuni',
      'fa-mobile',
      'fa-mobile-alt',
      'fa-modx',
      'fa-monero',
      'fa-money-bill',
      'fa-money-bill-alt',
      'fa-money-bill-wave',
      'fa-money-bill-wave-alt',
      'fa-money-check',
      'fa-money-check-alt',
      'fa-monument',
      'fa-moon',
      'fa-mortar-pestle',
      'fa-mosque',
      'fa-motorcycle',
      'fa-mountain',
      'fa-mouse',
      'fa-mouse-pointer',
      'fa-mug-hot',
      'fa-music',
      'fa-napster',
      'fa-neos',
      'fa-network-wired',
      'fa-neuter',
      'fa-newspaper',
      'fa-nimblr',
      'fa-node',
      'fa-node-js',
      'fa-not-equal',
      'fa-notes-medical',
      'fa-npm',
      'fa-ns8',
      'fa-nutritionix',
      'fa-object-group',
      'fa-object-ungroup',
      'fa-octopus-deploy',
      'fa-odnoklassniki',
      'fa-odnoklassniki-square',
      'fa-oil-can',
      'fa-old-republic',
      'fa-om',
      'fa-opencart',
      'fa-openid',
      'fa-opera',
      'fa-optin-monster',
      'fa-orcid',
      'fa-osi',
      'fa-otter',
      'fa-outdent',
      'fa-page4',
      'fa-pagelines',
      'fa-pager',
      'fa-paint-brush',
      'fa-paint-roller',
      'fa-palette',
      'fa-palfed',
      'fa-pallet',
      'fa-paper-plane',
      'fa-paperclip',
      'fa-parachute-box',
      'fa-paragraph',
      'fa-parking',
      'fa-passport',
      'fa-pastafarianism',
      'fa-paste',
      'fa-patreon',
      'fa-pause',
      'fa-pause-circle',
      'fa-paw',
      'fa-paypal',
      'fa-peace',
      'fa-pen',
      'fa-pen-alt',
      'fa-pen-fancy',
      'fa-pen-nib',
      'fa-pen-square',
      'fa-pencil-alt',
      'fa-pencil-ruler',
      'fa-penny-arcade',
      'fa-people-arrows',
      'fa-people-carry',
      'fa-pepper-hot',
      'fa-perbyte',
      'fa-percent',
      'fa-percentage',
      'fa-periscope',
      'fa-person-booth',
      'fa-phabricator',
      'fa-phoenix-framework',
      'fa-phoenix-squadron',
      'fa-phone',
      'fa-phone-alt',
      'fa-phone-slash',
      'fa-phone-square',
      'fa-phone-square-alt',
      'fa-phone-volume',
      'fa-photo-video',
      'fa-php',
      'fa-pied-piper',
      'fa-pied-piper-alt',
      'fa-pied-piper-hat',
      'fa-pied-piper-pp',
      'fa-pied-piper-square',
      'fa-piggy-bank',
      'fa-pills',
      'fa-pinterest',
      'fa-pinterest-p',
      'fa-pinterest-square',
      'fa-pizza-slice',
      'fa-place-of-worship',
      'fa-plane',
      'fa-plane-arrival',
      'fa-plane-departure',
      'fa-plane-slash',
      'fa-play',
      'fa-play-circle',
      'fa-playstation',
      'fa-plug',
      'fa-plus',
      'fa-plus-circle',
      'fa-plus-square',
      'fa-podcast',
      'fa-poll',
      'fa-poll-h',
      'fa-poo',
      'fa-poo-storm',
      'fa-poop',
      'fa-portrait',
      'fa-pound-sign',
      'fa-power-off',
      'fa-pray',
      'fa-praying-hands',
      'fa-prescription',
      'fa-prescription-bottle',
      'fa-prescription-bottle-alt',
      'fa-print',
      'fa-procedures',
      'fa-product-hunt',
      'fa-project-diagram',
      'fa-pump-medical',
      'fa-pump-soap',
      'fa-pushed',
      'fa-puzzle-piece',
      'fa-python',
      'fa-qq',
      'fa-qrcode',
      'fa-question',
      'fa-question-circle',
      'fa-quidditch',
      'fa-quinscape',
      'fa-quora',
      'fa-quote-left',
      'fa-quote-right',
      'fa-quran',
      'fa-r-project',
      'fa-radiation',
      'fa-radiation-alt',
      'fa-rainbow',
      'fa-random',
      'fa-raspberry-pi',
      'fa-ravelry',
      'fa-react',
      'fa-reacteurope',
      'fa-readme',
      'fa-rebel',
      'fa-receipt',
      'fa-record-vinyl',
      'fa-recycle',
      'fa-red-river',
      'fa-reddit',
      'fa-reddit-alien',
      'fa-reddit-square',
      'fa-redhat',
      'fa-redo',
      'fa-redo-alt',
      'fa-registered',
      'fa-remove-format',
      'fa-renren',
      'fa-reply',
      'fa-reply-all',
      'fa-replyd',
      'fa-republican',
      'fa-researchgate',
      'fa-resolving',
      'fa-restroom',
      'fa-retweet',
      'fa-rev',
      'fa-ribbon',
      'fa-ring',
      'fa-road',
      'fa-robot',
      'fa-rocket',
      'fa-rocketchat',
      'fa-rockrms',
      'fa-route',
      'fa-rss',
      'fa-rss-square',
      'fa-ruble-sign',
      'fa-ruler',
      'fa-ruler-combined',
      'fa-ruler-horizontal',
      'fa-ruler-vertical',
      'fa-running',
      'fa-rupee-sign',
      'fa-rust',
      'fa-sad-cry',
      'fa-sad-tear',
      'fa-safari',
      'fa-salesforce',
      'fa-sass',
      'fa-satellite',
      'fa-satellite-dish',
      'fa-save',
      'fa-schlix',
      'fa-school',
      'fa-screwdriver',
      'fa-scribd',
      'fa-scroll',
      'fa-sd-card',
      'fa-search',
      'fa-search-dollar',
      'fa-search-location',
      'fa-search-minus',
      'fa-search-plus',
      'fa-searchengin',
      'fa-seedling',
      'fa-sellcast',
      'fa-sellsy',
      'fa-server',
      'fa-servicestack',
      'fa-shapes',
      'fa-share',
      'fa-share-alt',
      'fa-share-alt-square',
      'fa-share-square',
      'fa-shekel-sign',
      'fa-shield-alt',
      'fa-shield-virus',
      'fa-ship',
      'fa-shipping-fast',
      'fa-shirtsinbulk',
      'fa-shoe-prints',
      'fa-shopify',
      'fa-shopping-bag',
      'fa-shopping-basket',
      'fa-shopping-cart',
      'fa-shopware',
      'fa-shower',
      'fa-shuttle-van',
      'fa-sign',
      'fa-sign-in-alt',
      'fa-sign-language',
      'fa-sign-out-alt',
      'fa-signal',
      'fa-signature',
      'fa-sim-card',
      'fa-simplybuilt',
      'fa-sink',
      'fa-sistrix',
      'fa-sitemap',
      'fa-sith',
      'fa-skating',
      'fa-sketch',
      'fa-skiing',
      'fa-skiing-nordic',
      'fa-skull',
      'fa-skull-crossbones',
      'fa-skyatlas',
      'fa-skype',
      'fa-slack',
      'fa-slack-hash',
      'fa-slash',
      'fa-sleigh',
      'fa-sliders-h',
      'fa-slideshare',
      'fa-smile',
      'fa-smile-beam',
      'fa-smile-wink',
      'fa-smog',
      'fa-smoking',
      'fa-smoking-ban',
      'fa-sms',
      'fa-snapchat',
      'fa-snapchat-ghost',
      'fa-snapchat-square',
      'fa-snowboarding',
      'fa-snowflake',
      'fa-snowman',
      'fa-snowplow',
      'fa-soap',
      'fa-socks',
      'fa-solar-panel',
      'fa-sort',
      'fa-sort-alpha-down',
      'fa-sort-alpha-down-alt',
      'fa-sort-alpha-up',
      'fa-sort-alpha-up-alt',
      'fa-sort-amount-down',
      'fa-sort-amount-down-alt',
      'fa-sort-amount-up',
      'fa-sort-amount-up-alt',
      'fa-sort-down',
      'fa-sort-numeric-down',
      'fa-sort-numeric-down-alt',
      'fa-sort-numeric-up',
      'fa-sort-numeric-up-alt',
      'fa-sort-up',
      'fa-soundcloud',
      'fa-sourcetree',
      'fa-spa',
      'fa-space-shuttle',
      'fa-speakap',
      'fa-speaker-deck',
      'fa-spell-check',
      'fa-spider',
      'fa-spinner',
      'fa-splotch',
      'fa-spotify',
      'fa-spray-can',
      'fa-square',
      'fa-square-full',
      'fa-square-root-alt',
      'fa-squarespace',
      'fa-stack-exchange',
      'fa-stack-overflow',
      'fa-stackpath',
      'fa-stamp',
      'fa-star',
      'fa-star-and-crescent',
      'fa-star-half',
      'fa-star-half-alt',
      'fa-star-of-david',
      'fa-star-of-life',
      'fa-staylinked',
      'fa-steam',
      'fa-steam-square',
      'fa-steam-symbol',
      'fa-step-backward',
      'fa-step-forward',
      'fa-stethoscope',
      'fa-sticker-mule',
      'fa-sticky-note',
      'fa-stop',
      'fa-stop-circle',
      'fa-stopwatch',
      'fa-stopwatch-20',
      'fa-store',
      'fa-store-alt',
      'fa-store-alt-slash',
      'fa-store-slash',
      'fa-strava',
      'fa-stream',
      'fa-street-view',
      'fa-strikethrough',
      'fa-stripe',
      'fa-stripe-s',
      'fa-stroopwafel',
      'fa-studiovinari',
      'fa-stumbleupon',
      'fa-stumbleupon-circle',
      'fa-subscript',
      'fa-subway',
      'fa-suitcase',
      'fa-suitcase-rolling',
      'fa-sun',
      'fa-superpowers',
      'fa-superscript',
      'fa-supple',
      'fa-surprise',
      'fa-suse',
      'fa-swatchbook',
      'fa-swift',
      'fa-swimmer',
      'fa-swimming-pool',
      'fa-symfony',
      'fa-synagogue',
      'fa-sync',
      'fa-sync-alt',
      'fa-syringe',
      'fa-table',
      'fa-table-tennis',
      'fa-tablet',
      'fa-tablet-alt',
      'fa-tablets',
      'fa-tachometer-alt',
      'fa-tag',
      'fa-tags',
      'fa-tape',
      'fa-tasks',
      'fa-taxi',
      'fa-teamspeak',
      'fa-teeth',
      'fa-teeth-open',
      'fa-telegram',
      'fa-telegram-plane',
      'fa-temperature-high',
      'fa-temperature-low',
      'fa-tencent-weibo',
      'fa-tenge',
      'fa-terminal',
      'fa-text-height',
      'fa-text-width',
      'fa-th',
      'fa-th-large',
      'fa-th-list',
      'fa-the-red-yeti',
      'fa-theater-masks',
      'fa-themeco',
      'fa-themeisle',
      'fa-thermometer',
      'fa-thermometer-empty',
      'fa-thermometer-full',
      'fa-thermometer-half',
      'fa-thermometer-quarter',
      'fa-thermometer-three-quarters',
      'fa-think-peaks',
      'fa-thumbs-down',
      'fa-thumbs-up',
      'fa-thumbtack',
      'fa-ticket-alt',
      'fa-tiktok',
      'fa-times',
      'fa-times-circle',
      'fa-tint',
      'fa-tint-slash',
      'fa-tired',
      'fa-toggle-off',
      'fa-toggle-on',
      'fa-toilet',
      'fa-toilet-paper',
      'fa-toilet-paper-slash',
      'fa-toolbox',
      'fa-tools',
      'fa-tooth',
      'fa-torah',
      'fa-torii-gate',
      'fa-tractor',
      'fa-trade-federation',
      'fa-trademark',
      'fa-traffic-light',
      'fa-trailer',
      'fa-train',
      'fa-tram',
      'fa-transgender',
      'fa-transgender-alt',
      'fa-trash',
      'fa-trash-alt',
      'fa-trash-restore',
      'fa-trash-restore-alt',
      'fa-tree',
      'fa-trello',
      'fa-tripadvisor',
      'fa-trophy',
      'fa-truck',
      'fa-truck-loading',
      'fa-truck-monster',
      'fa-truck-moving',
      'fa-truck-pickup',
      'fa-tshirt',
      'fa-tty',
      'fa-tumblr',
      'fa-tumblr-square',
      'fa-tv',
      'fa-twitch',
      'fa-twitter',
      'fa-twitter-square',
      'fa-typo3',
      'fa-uber',
      'fa-ubuntu',
      'fa-uikit',
      'fa-umbraco',
      'fa-umbrella',
      'fa-umbrella-beach',
      'fa-uncharted',
      'fa-underline',
      'fa-undo',
      'fa-undo-alt',
      'fa-uniregistry',
      'fa-unity',
      'fa-universal-access',
      'fa-university',
      'fa-unlink',
      'fa-unlock',
      'fa-unlock-alt',
      'fa-unsplash',
      'fa-untappd',
      'fa-upload',
      'fa-ups',
      'fa-usb',
      'fa-user',
      'fa-user-alt',
      'fa-user-alt-slash',
      'fa-user-astronaut',
      'fa-user-check',
      'fa-user-circle',
      'fa-user-clock',
      'fa-user-cog',
      'fa-user-edit',
      'fa-user-friends',
      'fa-user-graduate',
      'fa-user-injured',
      'fa-user-lock',
      'fa-user-md',
      'fa-user-minus',
      'fa-user-ninja',
      'fa-user-nurse',
      'fa-user-plus',
      'fa-user-secret',
      'fa-user-shield',
      'fa-user-slash',
      'fa-user-tag',
      'fa-user-tie',
      'fa-user-times',
      'fa-users',
      'fa-users-cog',
      'fa-users-slash',
      'fa-usps',
      'fa-ussunnah',
      'fa-utensil-spoon',
      'fa-utensils',
      'fa-vaadin',
      'fa-vector-square',
      'fa-venus',
      'fa-venus-double',
      'fa-venus-mars',
      'fa-vest',
      'fa-vest-patches',
      'fa-viacoin',
      'fa-viadeo',
      'fa-viadeo-square',
      'fa-vial',
      'fa-vials',
      'fa-viber',
      'fa-video',
      'fa-video-slash',
      'fa-vihara',
      'fa-vimeo',
      'fa-vimeo-square',
      'fa-vimeo-v',
      'fa-vine',
      'fa-virus',
      'fa-virus-slash',
      'fa-viruses',
      'fa-vk',
      'fa-vnv',
      'fa-voicemail',
      'fa-volleyball-ball',
      'fa-volume-down',
      'fa-volume-mute',
      'fa-volume-off',
      'fa-volume-up',
      'fa-vote-yea',
      'fa-vr-cardboard',
      'fa-vuejs',
      'fa-walking',
      'fa-wallet',
      'fa-warehouse',
      'fa-watchman-monitoring',
      'fa-water',
      'fa-wave-square',
      'fa-waze',
      'fa-weebly',
      'fa-weibo',
      'fa-weight',
      'fa-weight-hanging',
      'fa-weixin',
      'fa-whatsapp',
      'fa-whatsapp-square',
      'fa-wheelchair',
      'fa-whmcs',
      'fa-wifi',
      'fa-wikipedia-w',
      'fa-wind',
      'fa-window-close',
      'fa-window-maximize',
      'fa-window-minimize',
      'fa-window-restore',
      'fa-windows',
      'fa-wine-bottle',
      'fa-wine-glass',
      'fa-wine-glass-alt',
      'fa-wix',
      'fa-wizards-of-the-coast',
      'fa-wodu',
      'fa-wolf-pack-battalion',
      'fa-won-sign',
      'fa-wordpress',
      'fa-wordpress-simple',
      'fa-wpbeginner',
      'fa-wpexplorer',
      'fa-wpforms',
      'fa-wpressr',
      'fa-wrench',
      'fa-x-ray',
      'fa-xbox',
      'fa-xing',
      'fa-xing-square',
      'fa-y-combinator',
      'fa-yahoo',
      'fa-yammer',
      'fa-yandex',
      'fa-yandex-international',
      'fa-yarn',
      'fa-yelp',
      'fa-yen-sign',
      'fa-yin-yang',
      'fa-yoast',
      'fa-youtube',
      'fa-youtube-square',
      'fa-zhihu',
      ]

    };
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>


